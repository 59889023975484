import React, { FunctionComponent } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Card from "../components/card"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faMicrochip,
    faInfo,
    faMeteor,
    faCode,
    faTools,
    faClipboard,
    faLightbulb,
    faRocket,
} from "@fortawesome/free-solid-svg-icons"

const query = graphql`
    query GetCustomers {
        allFile(filter: { relativeDirectory: { eq: "customers" } }) {
            edges {
                node {
                    id
                    publicURL
                    childImageSharp {
                        fixed(height: 75) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }
`

// markup
const IndexPage = () => {
    const data = useStaticQuery(query)
    return (
        <Layout title="Home">
            <div className="mx-auto md:container md:rounded-lg shadow-lg border mb-6">
                <div className="bg-pcb md:rounded-t-lg shadow-lg h-40 md:h-80 bg-cover bg-clip-content bg-no-repeat bg-center" />
                <div className="p-6">
                    <div className="text-4xl">
                        Specializing in High Density Advanced Packaging / Fan
                        Out Wafer Level and High Speed and High Quality PCB
                        Designs
                    </div>
                </div>
            </div>

            <div className="mx-auto container">
                <div className="grid md:grid-cols-3 gap-4">
                    <Card title="Who We Are" icon={faClipboard}>
                        <p>
                            At HSDS we provide the real-life experiences of
                            completing High Speed designs with high pin count
                            FPGA’s and delivering complex designs others cannot
                            achieve.
                        </p>
                        <p>
                            Here at HSDS, we stand behind our statement: "The
                            First Revision is going to be the Last One“ with
                            complete design services that include electrical and
                            mechanical hardware engineering, signal integrity
                            analysis, logical and physical library development,
                            and our core competency, PCB layout.
                        </p>
                    </Card>
                    <Card title="Tools" icon={faTools}>
                        <p>
                            We own Expedition Advanced Interconnect licenses and
                            possess a higher-level of experience on all the
                            latest in software for Specializing in High Density
                            Advanced Packaging / Fan Out Wafer Level Design,
                            High Speed and High Quality PCB Designs, and
                            High-Speed SI simulation; Expedition, CES,
                            Expedition RFLayout, IOD IO Designer, DX_Designer,
                            Mentor Graphics Board Station RE, Allegro 16.x/CMS,
                            Hyperlynx, Power SI from Sigrity, ICX, and ICX Pro
                            Verify.
                        </p>
                    </Card>
                    <Card title="State of the Art" icon={faMeteor}>
                        <p>
                            What this means to you is that we are able to
                            provide the highest quality of work with cost
                            savings to you using state of the art CAD Tools.
                        </p>

                        <p>
                            Experienced senior level High Density Advanced
                            Packaging, PCB, and SI Engineering staff is poised
                            to problem solving and providing solutions that
                            others can not achieve!
                        </p>
                    </Card>
                </div>

                <Card
                    title="We Provide Concept to Creation, with Confidence"
                    icon={faLightbulb}
                >
                    <p>
                        To provide world class PCB design and Signal Integrity
                        services with our many years of experience. Focusing on
                        completing complex high-speed designs that others cannot
                        achieve. Designs are driven by high-speed performance,
                        that are validated through simulation and best practice
                        techniques which are embedded in our tested and proven
                        PCB process, including electrical constraints derived
                        from simulations. At High Speed Design Services, LLC we
                        provide more than just an extra pair of hands for PCB
                        design and SI Analysis and verification. We offer
                        leading edge PCB/SI design techniques, methodologies,
                        and training. High Speed Design Services possess senior
                        level skill sets to deliver top-notch world-class
                        results in a timely manner. At High Speed Design
                        Services we operate as an “A team” to assist your
                        internal engineer resources or HSDS can be your engineer
                        resource solution.
                    </p>
                </Card>

                <Card title="Active Customers">
                    <div className="grid px-2 lg:grid-cols-4 gap-4">
                        {data.allFile.edges.map((customer) =>
                            customer.node.childImageSharp ? (
                                <Img
                                    fixed={customer.node.childImageSharp.fixed}
                                    className="mx-auto"
                                />
                            ) : (
                                <img
                                    className="mx-auto"
                                    style={{ height: "75px" }}
                                    key={customer.node.id}
                                    src={customer.node.publicURL}
                                />
                            )
                        )}
                    </div>
                </Card>
            </div>
        </Layout>
    )
}

export default IndexPage

import React, { FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"

type CardProps = {
    title?: String
    icon?: IconDefinition
    children?: React.ReactNode
}

const Card: FunctionComponent<CardProps> = ({ title, icon, children }) => {
    const Title = () => {
        if (title) {
            return <h3>{title}</h3>
        } else {
            return null
        }
    }
    return (
        <div className="shadow-lg m-4 border p-4">
            <div className="text-center">
                <FontAwesomeIcon
                    icon={icon}
                    size="4x"
                    className="text-gray-400"
                />
                <Title />
            </div>
            <div>{children}</div>
        </div>
    )
}

export default Card
